import LocalStorage from './localStorage'

const localStorage = new LocalStorage('evestment:jwt:configuration:')

function trimUrl (value) {
  if (value.length > 0 && value[value.length - 1] === '/') {
    return value.substring(0, value.length - 1)
  }

  return value
}

// Deprecated - Retained for backwards compatibility
const baseUrl = {
  setValue: function (value) {
    if (!value) {
      const protocol = window.location.protocol
      const hostname = window.location.hostname
      value = protocol + '//' + hostname
    }

    value = trimUrl(value)

    localStorage.setItem('baseUrl', value)
  },
  getValue: function () {
    var value = localStorage.getItem('baseUrl')

    if (!value) {
      const protocol = window.location.protocol
      const hostname = window.location.hostname
      value = protocol + '//' + hostname
    }

    return value
  }
}

const appBaseUrl = {
  setValue: function (value) {
    value = trimUrl(value)

    localStorage.setItem('appBaseUrl', value)
  },
  getValue: function () {
    var value = localStorage.getItem('appBaseUrl')

    if (!value) {
      value = baseUrl.getValue()
    }

    return value
  }
}

const identityBaseUrl = {
  setValue: function (value) {
    value = trimUrl(value)

    localStorage.setItem('identityBaseUrl', value)
  },
  getValue: function () {
    var value = localStorage.getItem('identityBaseUrl')

    if (!value) {
      value = baseUrl.getValue()
    }

    return value
  }
}

const loginPath = {
  setValue: function (value) {
    if (!value) {
      value = ''
    }

    localStorage.setItem('loginPath', value)
  },
  getValue: function () {
    var value = localStorage.getItem('loginPath')

    if (!value && value !== '') {
      value = '/next/login.aspx'
    }

    return value
  }
}

const loginCallbackPath = {
  setValue: function (value) {
    if (!value) {
      value = ''
    }

    localStorage.setItem('loginCallbackPath', value)
  },
  getValue: function () {
    var value = localStorage.getItem('loginCallbackPath')

    if (!value && value !== '') {
      value = '/next/login-callback.aspx'
    }

    return value
  }
}

const logoutPath = {
  setValue: function (value) {
    if (!value) {
      value = ''
    }

    localStorage.setItem('logoutPath', value)
  },
  getValue: function () {
    var value = localStorage.getItem('logoutPath')

    if (!value && value !== '') {
      value = '/next/logout.aspx'
    }

    return value
  }
}

const refreshDisabledPaths = {
  setValue: function (value) {
    if (!value) {
      value = []
    }

    var json = JSON.stringify(value.map(e => e.toLowerCase()))
    localStorage.setItem('refreshDisabledPaths', json)
  },
  getValue: function () {
    var value = JSON.parse(localStorage.getItem('refreshDisabledPaths'))

    value = value || []

    // for backwards compatibility
    value = value.concat([
      '/next/login-callback.aspx',
      '/next/login-dream.aspx',
      '/next/login-post.aspx',
      '/next/login-rules-1.aspx',
      '/next/login-rules-2.aspx',
      '/next/login-session-locked.aspx',
      '/next/login.aspx',
      '/next/logout.aspx'
    ])
    value.push(loginPath.getValue().toLowerCase())
    value.push(loginCallbackPath.getValue().toLowerCase())
    value.push(logoutPath.getValue().toLowerCase())

    return [...new Set(value)]
  }
}

const cookiePath = {
  setValue: function (value) {
    if (!value) {
      value = ''
    }

    localStorage.setItem('cookiePath', value)
  },
  getValue: function () {
    var value = localStorage.getItem('cookiePath')

    if (!value && value !== '') {
      value = '/api/private/v1/authenticate/access-token/cookie'
    }

    return value
  }
}

const clientName = {
  setValue: function (value) {
    localStorage.setItem('clientName', value)
  },
  getValue: function () {
    var value = localStorage.getItem('clientName')

    if (!value) {
      value = 'spa'
    }

    return value
  }
}

const redirectUri = {
  setValue: function (value) {
    // ensure value
    if (!value) {
      value = ''
    }
    // store
    localStorage.setItem('redirectUri', value)
  },
  getValue: function () {
    // get
    var value = localStorage.getItem('redirectUri')

    // ensure value
    if (!value && value !== '') {
      value = appBaseUrl.getValue() + loginCallbackPath.getValue()
    }
    return value
  }
}

const isEvestment = {
  setValue: function (value) {
    value = (value === false ? 'false' : 'true')

    localStorage.setItem('isEvestment', value)
  },
  getValue: function () {
    var value = localStorage.getItem('isEvestment')

    return value !== 'false'
  }
}

const properties = {
  baseUrl,
  appBaseUrl,
  identityBaseUrl,
  loginPath,
  loginCallbackPath,
  logoutPath,
  refreshDisabledPaths,
  cookiePath,
  clientName,
  redirectUri,
  isEvestment
}

function set (configs) {
  Object.keys(configs || {})
    .filter(k => properties[k])
    .forEach(k => properties[k].setValue(configs[k]))
}

export default {
  ...properties,
  set
}
