import configuration from './configuration'

function invokeCallback (callback, args) {
  if (callback) {
    args ? callback.apply(this, args) : callback()
  }
}

export default {
  set: function (accessToken, callback) {
    try {
      var xeva = accessToken ? encodeURIComponent(accessToken.substring(0, 10)) : 'missing'
      var url = configuration.appBaseUrl.getValue() + configuration.cookiePath.getValue() + '?_dc=' + new Date().getTime() + '&_xeva=' + xeva
      var xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        try {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 204 || xhr.status === 1223) {
              invokeCallback(callback)
            } else {
              var error = new Error(xhr.statusText)
              error.response = xhr
              invokeCallback(callback, [error])
            }
          }
        } catch (e) {
          console.error(e)
          invokeCallback(callback, [e])
        }
      }
      xhr.open('PUT', url, true)
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
      xhr.setRequestHeader('X-EVESTMENT-AUTHORIZATION', accessToken)
      xhr.send()
    } catch (e) {
      console.error(e)
      invokeCallback(callback, [e])
    }
  },
  remove: function (callback) {
    try {
      var url = configuration.appBaseUrl.getValue() + configuration.cookiePath.getValue() + '?_dc=' + new Date().getTime()
      var xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        try {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 204 || xhr.status === 1223) {
              invokeCallback(callback)
            } else {
              var error = new Error(xhr.statusText)
              error.response = xhr
              invokeCallback(callback, [error])
            }
          }
        } catch (e) {
          console.error(e)
          invokeCallback(callback, [e])
        }
      }
      xhr.open('DELETE', url, true)
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
      xhr.send()
    } catch (e) {
      console.error(e)
      invokeCallback(callback, [e])
    }
  }
}
