import { Auth0Client } from '@auth0/auth0-spa-js'
import accessTokenCookie from './accessTokenCookie'
import configuration from './configuration'
import LocalStorage from './localStorage'
import logging from './logging'
import jwtDecode from 'jwt-decode'
import 'whatwg-fetch'

const initialized = true
const localStorage = new LocalStorage('evestment:jwt:authorizationcodeflow:')
var hasLoadedRemoteConfigs = false

function invokeCallback (callback, args) {
  if (callback) {
    args ? callback.apply(this, args) : callback()
  }
}

function isActive () {
  return !!localStorage.getItem('active')
}

function hasBeenActive () {
  return !!localStorage.getItem('hasBeenActive')
}

function loadRemoteConfigs (callback) {
  if (hasLoadedRemoteConfigs && localStorage.getItem('domain') && localStorage.getItem('client_id') && localStorage.getItem('audience')) return invokeCallback(callback)

  // get configs
  const fetchOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  window
    .fetch(configuration.identityBaseUrl.getValue() + '/services/identity/v1/authentication/a0config/' + configuration.clientName.getValue(), fetchOptions)
    .then((res) => {
      if (res.status !== 200) {
        let error = new Error(res.statusText)
        error.response = res
        throw error
      }
      return res
    })
    .then((res) => res.json())
    .then((data) => {
      if (!data) return invokeCallback(callback)
      if (!data.domain) return invokeCallback(callback)
      if (!data.clientId) return invokeCallback(callback)
      if (!data.audience) return invokeCallback(callback)

      localStorage.setItem('domain', data.domain)
      localStorage.setItem('client_id', data.clientId)
      localStorage.setItem('audience', data.audience)
      hasLoadedRemoteConfigs = true

      invokeCallback(callback)
    })
    .catch((err) => {
      console.error(err)
      invokeCallback(callback)
    })
}

function init (callback) {
  if (window._auth0) return invokeCallback(callback)

  loadRemoteConfigs(function () {
    const redirectUri = configuration.redirectUri.getValue()
    // sdk
    window._auth0 = new Auth0Client({
      domain: localStorage.getItem('domain'),
      client_id: localStorage.getItem('client_id'),
      audience: localStorage.getItem('audience'),
      redirect_uri: redirectUri,
      cacheLocation: 'localstorage',
      useRefreshTokens: true
    })

    // callback
    invokeCallback(callback)
  })
}

function setExpirationValuesInLocalStorage (token) {
  let accessTokenClaims = jwtDecode(token)
  let issuedOnSecondsSinceEpoch = accessTokenClaims['iat']
  let expiresOnSecondsSinceEpoch = accessTokenClaims['exp']
  const expiresIn = (expiresOnSecondsSinceEpoch - issuedOnSecondsSinceEpoch) * 1000 // javascript works in milliseconds so converting to ms for convenience
  localStorage.setItem('access_token_expires_in', expiresIn)
  const expiresOn = new Date(expiresOnSecondsSinceEpoch * 1000) // expiration date in seconds since epoch * 1000 to turn it into milliseconds
  localStorage.setItem('access_token_expires_on', expiresOn)

  return expiresOn
}

function refreshAuth0 (options, callback) {
  // options
  options = options || {}

  // init
  init(function () {
    // getTokenSilentlyOptions
    var getTokenSilentlyOptions = { ignoreCache: true }

    // stateful claims
    if (options && options.serviceLoginUsername) {
      getTokenSilentlyOptions.serviceLoginUsername = options.serviceLoginUsername
    }

    if (options && options.sessionPersistenceId) {
      getTokenSilentlyOptions.sessionPersistenceId = options.sessionPersistenceId
    }

    if (options && options.sessionPersistenceIdSignature) {
      getTokenSilentlyOptions.sessionPersistenceIdSignature = options.sessionPersistenceIdSignature
    }

    // getTokenSilently
    window._auth0
      .getTokenSilently(getTokenSilentlyOptions)
      .then(function (token) {
        accessTokenCookie.set(token, (err) => {
          try {
            if (err) {
              logging.log(err, () => { logout(null, callback) })
            } else {
              setExpirationValuesInLocalStorage(token)
              invokeCallback(callback)
            }
          } catch (e) {
            console.log(e)
            invokeCallback(callback)
          }
        })
      })
      .catch(function (err) {
        logging.log(err, () => { logout(null, callback) })
      })
  })
}

function refresh (options, callback) {
  // options
  options = options || {}

  // active?
  if (!localStorage.getItem('active')) {
    return invokeCallback(callback)
  }

  // validation
  if (localStorage.isLocked()) return invokeCallback(callback)

  // sdk
  init(function () {
    // refresh?
    const refreshTokenExpiresIn = localStorage.getItem('access_token_expires_in')
    const refreshTokenExpiresOn = localStorage.getItem('access_token_expires_on')
    const accessTokenTTL = new Date(refreshTokenExpiresOn) - new Date()
    const accessTokenHalfLife = refreshTokenExpiresIn / 2
    if (accessTokenTTL > accessTokenHalfLife) return invokeCallback(callback)

    // lock
    const lockTTL = 60 * 1000
    localStorage.lock(lockTTL)

    // getIdTokenClaims
    window._auth0.getIdTokenClaims()
      .then(function (idTokenClaims) {
        // stateful claims
        if (idTokenClaims) {
          options.serviceLoginUsername = idTokenClaims['https://app.evestment.com/identity/claims/serviceloginusername']
          options.sessionPersistenceId = idTokenClaims['https://app.evestment.com/identity/claims/sessionpersistenceid']
          options.sessionPersistenceIdSignature = idTokenClaims['https://app.evestment.com/identity/claims/sessionpersistenceidsignature']
        }

        // refresh
        refreshAuth0(options, () => {
          localStorage.unlock()
          invokeCallback(callback)
        })
      })
      .catch((e) => {
        console.log(e)
        localStorage.unlock()
        invokeCallback(callback)
      })
  })
}

function logout (options, callback) {
  // options
  options = options || {}

  // returnTo
  if (!options.localOnly && !options.returnTo) {
    options.returnTo = configuration.appBaseUrl.getValue() + configuration.logoutPath.getValue()
  }

  // cookie
  if (isActive()) {
    accessTokenCookie.remove((err) => {
      if (err) {
        console.log(err)
      }

      // local storage
      localStorage.removeItem('active')
      localStorage.removeItem('domain')
      localStorage.removeItem('client_id')
      localStorage.removeItem('audience')
      localStorage.removeItem('access_token_expires_in')
      localStorage.removeItem('access_token_expires_on')

      // sdk
      init(function () {
        // logout
        window._auth0.logout(options)

        // callback
        if (options.localOnly === true) {
          invokeCallback(callback)
        }
      })
    })
  } else {
    // local storage
    localStorage.removeItem('active')
    localStorage.removeItem('domain')
    localStorage.removeItem('client_id')
    localStorage.removeItem('audience')
    localStorage.removeItem('access_token_expires_in')
    localStorage.removeItem('access_token_expires_on')

    // sdk
    init(function () {
      // logout
      window._auth0.logout(options)

      // callback
      if (options.localOnly === true) {
        invokeCallback(callback)
      }
    })
  }
}

function handleRedirectCallback (callback) {
  if (!window) return invokeCallback(callback)

  init(function () {
    window._auth0
      .handleRedirectCallback()
      .then(() => {
        window._auth0
          .getTokenSilently()
          .then(token => {
            accessTokenCookie.set(token, (err) => {
              try {
                if (err) {
                  logging.log(err)
                } else {
                  setExpirationValuesInLocalStorage(token)
                  localStorage.setItem('active', true)
                  localStorage.setItem('hasBeenActive', true)
                }
              } catch (e) {
                logging.log(e)
              } finally {
                invokeCallback(callback)
              }
            })
          })
          .catch((e) => {
            logging.log(e, () => { invokeCallback(callback) })
          })
      })
      .catch((e) => {
        logging.log(e, () => { invokeCallback(callback) })
      })
  })
}

function loginWithRedirect ({ extraParams = {} } = {}) {
  if (!window) return

  init(function () {
    const uri = configuration.redirectUri.getValue()
    window._auth0.loginWithRedirect({
      ...extraParams,
      redirect_uri: uri
    })
  })
}

function loginWithPopup (callback) {
  if (!window) return

  init(function () {
    window._auth0.loginWithPopup().then(value => {
      tryGetTokenSilently(callback)
    }, reason => {
      invokeCallback(callback, [reason])
    })
  })
}

function tryGetTokenSilently (callback) {
  if (!window) return

  init(function () {
    window._auth0
      .getTokenSilently()
      .then(token => {
        accessTokenCookie.set(token, (err) => {
          if (err) {
            invokeCallback(callback, [err])
          } else {
            setExpirationValuesInLocalStorage(token)
            localStorage.setItem('active', true)
            localStorage.setItem('hasBeenActive', true)
          }
          invokeCallback(callback)
        })
      }, reason => {
        invokeCallback(callback, [reason])
      })
      .catch(function (err) {
        console.error(err)
        invokeCallback(callback, [err])
      })
  })
}

function serviceLoginStart (serviceLoginUsername, callback) {
  refreshAuth0({ serviceLoginUsername: serviceLoginUsername }, callback)
}

function serviceLoginStop (callback) {
  refreshAuth0({}, callback)
}

// init
if (isActive()) {
  localStorage.setItem('hasBeenActive', true)
}

// export
export default {
  initialized,
  init,
  isActive,
  hasBeenActive,
  refresh,
  logout,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  serviceLoginStart,
  serviceLoginStop,
  tryGetTokenSilently
}
