import configuration from './configuration'
import resourceOwnerCodeFlow from './resourceOwnerCodeFlow'
import authorizationCodeFlow from './authorizationCodeFlow'

const initialized = true

function onRefreshInterval () {
  // disable refresh during the login/logout pages
  const refreshDisabledPaths = configuration.refreshDisabledPaths.getValue()

  if (window && window.location && refreshDisabledPaths.indexOf(window.location.pathname.toLowerCase()) > -1) {
    return
  }

  var isResourceOwnerCodeFlowActive = window.jwt.resourceOwnerCodeFlow.isActive()
  var isAuthorizationCodeFlowActive = window.jwt.authorizationCodeFlow.isActive()

  // jitter for multiple instances/tabs running
  var jitter = Math.floor(Math.random() * 1000)
  setTimeout(() => {
    // prefer authorization code flow
    if (isAuthorizationCodeFlowActive) {
      window.jwt.authorizationCodeFlow.refresh()
    } else if (isResourceOwnerCodeFlowActive) {
      window.jwt.resourceOwnerCodeFlow.refresh()
    }
  }, jitter)
}

function logout (options, callback) {
  var isResourceOwnerCodeFlowActive = window.jwt.resourceOwnerCodeFlow.isActive()
  var isAuthorizationCodeFlowActive = window.jwt.authorizationCodeFlow.isActive()
  var isOnLogoutPage = window.location.pathname === configuration.logoutPath.getValue()

  if (isResourceOwnerCodeFlowActive) {
    window.jwt.resourceOwnerCodeFlow.logout(options, callback)
  }

  if (isAuthorizationCodeFlowActive) {
    window.jwt.authorizationCodeFlow.logout(options, callback)
  }

  if (!isResourceOwnerCodeFlowActive && !isAuthorizationCodeFlowActive) {
    if (!options || options.localOnly !== true) {
      window.location = configuration.appBaseUrl.getValue() + (isOnLogoutPage ? configuration.loginPath.getValue() : configuration.logoutPath.getValue())
    }
    if (callback) {
      callback()
    }
  }
}

function serviceLoginStart (serviceLoginUsername, callback) {
  var isResourceOwnerCodeFlowActive = window.jwt.resourceOwnerCodeFlow.isActive()
  var isAuthorizationCodeFlowActive = window.jwt.authorizationCodeFlow.isActive()

  // prefer authorization code flow
  if (isAuthorizationCodeFlowActive) {
    window.jwt.authorizationCodeFlow.serviceLoginStart(serviceLoginUsername, callback)
  } else if (isResourceOwnerCodeFlowActive) {
    window.jwt.resourceOwnerCodeFlow.serviceLoginStart(serviceLoginUsername, callback)
  }

  if (!isResourceOwnerCodeFlowActive && !isAuthorizationCodeFlowActive && callback) {
    callback()
  }
}

function serviceLoginStop (callback) {
  var isResourceOwnerCodeFlowActive = window.jwt.resourceOwnerCodeFlow.isActive()
  var isAuthorizationCodeFlowActive = window.jwt.authorizationCodeFlow.isActive()

  if (isResourceOwnerCodeFlowActive) {
    window.jwt.resourceOwnerCodeFlow.serviceLoginStop(callback)
  }

  if (isAuthorizationCodeFlowActive) {
    window.jwt.authorizationCodeFlow.serviceLoginStop(callback)
  }

  if (!isResourceOwnerCodeFlowActive && !isAuthorizationCodeFlowActive && callback) {
    callback()
  }
}

if (window) {
  window.jwt = window.jwt || {}
  window.jwt.configuration = window.jwt.configuration || configuration
  window.jwt.resourceOwnerCodeFlow = window.jwt.resourceOwnerCodeFlow || resourceOwnerCodeFlow
  window.jwt.authorizationCodeFlow = window.jwt.authorizationCodeFlow || authorizationCodeFlow
  window.jwt.logout = window.jwt.logout || logout
  window.jwt.serviceLoginStart = window.jwt.serviceLoginStart || serviceLoginStart
  window.jwt.serviceLoginStop = window.jwt.serviceLoginStop || serviceLoginStop

  // ensure page load runs interval at least 1 time
  onRefreshInterval()
  window.jwt._refreshInterval = window.jwt._refreshInterval || setInterval(onRefreshInterval, 15000) // every 15 seconds
}

export default {
  initialized
}
