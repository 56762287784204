const LocalStorage = function (prefix) {
  var self = this
  self.initialized = false
  self.NS_ERROR_FILE_CORRUPTED = false
  self.QuotaExceededError = false

  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    self.initialized = true
  } catch (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      self.NS_ERROR_FILE_CORRUPTED = true
    }
    if (e.name === 'QuotaExceededError') {
      self.QuotaExceededError = true
    }
  }

  self.handleException = function (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      self.NS_ERROR_FILE_CORRUPTED = true
      return
    }

    if (e.name === 'QuotaExceededError') {
      self.QuotaExceededError = true
      return
    }

    throw e
  }

  return {
    setItem: function (key, value) {
      if (self.initialized && !self.NS_ERROR_FILE_CORRUPTED) {
        try {
          window.localStorage.setItem(`${prefix}${key}`, value)
        } catch (e) {
          self.handleException(e)
        }
      }
    },
    getItem: function (key) {
      if (self.initialized && !self.NS_ERROR_FILE_CORRUPTED) {
        try {
          return window.localStorage.getItem(`${prefix}${key}`)
        } catch (e) {
          self.handleException(e)
        }
      }
    },
    removeItem: function (key) {
      if (self.initialized && !self.NS_ERROR_FILE_CORRUPTED) {
        try {
          window.localStorage.removeItem(`${prefix}${key}`)
        } catch (e) {
          self.handleException(e)
        }
      }
    },
    lock: function (ttl) {
      if (self.initialized && !self.NS_ERROR_FILE_CORRUPTED) {
        try {
          let now = new Date().getTime()
          let expires = now + ttl
          window.localStorage.setItem(`${prefix}lock`, expires)
        } catch (e) {
          self.handleException(e)
        }
      }
    },
    unlock: function () {
      if (self.initialized && !self.NS_ERROR_FILE_CORRUPTED) {
        try {
          window.localStorage.removeItem(`${prefix}lock`)
        } catch (e) {
          self.handleException(e)
        }
      }
    },
    isLocked: function () {
      if (self.initialized && !self.NS_ERROR_FILE_CORRUPTED) {
        try {
          let now = new Date().getTime()
          let expires = window.localStorage.getItem(`${prefix}lock`)
          let isLocked = expires ? expires > now : false
          return isLocked
        } catch (e) {
          self.handleException(e)
        }
      }

      return false
    }
  }
}

LocalStorage.test = function () {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    return true
  } catch (e) { }

  return false
}

LocalStorage.test_NS_ERROR_FILE_CORRUPTED = function () {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    return false
  } catch (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      return true
    }
  }

  return false
}

LocalStorage.test_QuotaExceededError = function () {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    return false
  } catch (e) {
    if (e.name === 'QuotaExceededError') {
      return true
    }
  }

  return false
}

export default LocalStorage
