import 'whatwg-fetch'
import accessTokenCookie from './accessTokenCookie'
import configuration from './configuration'
import LocalStorage from './localStorage'
import jwtDecode from 'jwt-decode'
import 'url-search-params-polyfill'

const initialized = true
const localStorage = new LocalStorage('evestment:jwt:resourceownercodeflow:')
var hasLoadedRemoteConfigs = false

function invokeCallback (callback) {
  if (callback) {
    callback()
  }
}

function isActive () {
  return !!localStorage.getItem('active')
}

function hasBeenActive () {
  return !!localStorage.getItem('hasBeenActive')
}

function isAuthenticated () {
  // local storage
  let active = localStorage.getItem('active')
  let accessToken = localStorage.getItem('access_token')
  let refreshToken = localStorage.getItem('refresh_token')
  let accessTokenExpiresIn = localStorage.getItem('access_token_expires_in')
  let accessTokenExpiresOn = localStorage.getItem('access_token_expires_on')

  // is authenticated
  if (active && accessToken && refreshToken && accessTokenExpiresIn && accessTokenExpiresOn) {
    return true
  }

  return false
}

function loadRemoteConfigs (callback) {
  if (hasLoadedRemoteConfigs && localStorage.getItem('domain') && localStorage.getItem('client_id')) return invokeCallback(callback)

  // get configs
  const fetchOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  window
    .fetch(configuration.identityBaseUrl.getValue() + '/services/identity/v1/authentication/a0config/' + configuration.clientName.getValue(), fetchOptions)
    .then((res) => {
      if (res.status !== 200) {
        let error = new Error(res.statusText)
        error.response = res
        throw error
      }
      return res
    })
    .then((res) => res.json())
    .then((data) => {
      if (!data) return invokeCallback(callback)
      if (!data.domain) return invokeCallback(callback)
      if (!data.clientId) return invokeCallback(callback)

      localStorage.setItem('domain', data.domain)
      localStorage.setItem('client_id', data.clientId)
      hasLoadedRemoteConfigs = true

      invokeCallback(callback)
    })
    .catch((err) => {
      console.error(err)
      invokeCallback(callback)
    })
}

function init (options, callback) {
  // validation
  if (!options) return invokeCallback(callback)
  if (!options.access_token) return invokeCallback(callback)
  if (!options.refresh_token) return invokeCallback(callback)
  if (!options.expires_in) return invokeCallback(callback)
  if (!options.id_token) return invokeCallback(callback)

  // optimistic lock
  if (options.last_updated) {
    var lastUpdated = localStorage.getItem('last_updated')
    if (options.last_updated !== lastUpdated) return invokeCallback(callback)
  }

  // locals
  const expiresIn = options.expires_in * 1000
  const expiresOn = new Date(new Date().getTime() + expiresIn)

  // cookie
  accessTokenCookie.set(options.access_token, (err) => {
    if (err) {
      console.log(err)
      return invokeCallback(callback)
    }

    try {
      // local storage
      localStorage.setItem('active', true)
      localStorage.setItem('hasBeenActive', true)
      localStorage.setItem('access_token', options.access_token)
      localStorage.setItem('refresh_token', options.refresh_token)
      localStorage.setItem('id_token', options.id_token)
      localStorage.setItem('access_token_expires_in', expiresIn)
      localStorage.setItem('access_token_expires_on', expiresOn)
      localStorage.setItem('last_updated', new Date().getTime())
    } catch (e) {
      console.log(e)
    } finally {
      invokeCallback(callback)
    }
  })
}

function refreshAuth0 (domain, clientId, refreshToken, lastUpdated, requestBodyOptions, callback) {
  let baseRequestBody = {
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken
  }

  const requestBody = { ...baseRequestBody, ...requestBodyOptions }
  const fetchOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(requestBody)
  }

  window
    .fetch(`https://${domain}/oauth/token`, fetchOptions)
    .then((res) => {
      if (res.status !== 200) {
        let error = new Error(res.statusText)
        error.response = res
        throw error
      }
      return res
    })
    .then((res) => res.json())
    .then((data) => {
      data.last_updated = lastUpdated
      init(data, () => {
        invokeCallback(callback)
      })
    })
    .catch((err) => {
      console.error(err)
      logout(null, callback)
    })
}

function refresh (options, callback) {
  // local storage
  const refreshToken = localStorage.getItem('refresh_token')
  const refreshTokenExpiresIn = localStorage.getItem('access_token_expires_in')
  const refreshTokenExpiresOn = localStorage.getItem('access_token_expires_on')
  const lastUpdated = localStorage.getItem('last_updated')

  // isAuthenticated
  if (!isAuthenticated()) {
    return logout({ localOnly: true }, callback)
  }

  // validation
  if (localStorage.isLocked()) return invokeCallback(callback)

  // refresh?
  const accessTokenTTL = new Date(refreshTokenExpiresOn) - new Date()
  const accessTokenHalfLife = refreshTokenExpiresIn / 2
  if (accessTokenTTL > accessTokenHalfLife) return invokeCallback(callback)

  // lock
  const lockTTL = 60 * 1000
  localStorage.lock(lockTTL)

  loadRemoteConfigs(function () {
    const domain = localStorage.getItem('domain')
    const clientId = localStorage.getItem('client_id')

    const idToken = localStorage.getItem('id_token')
    let idTokenClaims = jwtDecode(idToken)
    let statefulClaims = {
      serviceLoginUsername: idTokenClaims['https://app.evestment.com/identity/claims/serviceloginusername'] || '',
      sessionPersistenceId: idTokenClaims['https://app.evestment.com/identity/claims/sessionpersistenceid'],
      sessionPersistenceIdSignature: idTokenClaims['https://app.evestment.com/identity/claims/sessionpersistenceidsignature']
    }

    // refresh
    refreshAuth0(domain, clientId, refreshToken, lastUpdated, statefulClaims, function () {
      localStorage.unlock()
      invokeCallback(callback)
    })
  })
}

function logout (options, callback) {
  // options
  options = options || {}

  // cookie
  if (isActive()) {
    accessTokenCookie.remove((err) => {
      if (err) {
        console.log(err)
      } else {
        // local storage
        localStorage.removeItem('active')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token_expires_in')
        localStorage.removeItem('access_token_expires_on')
        localStorage.removeItem('domain')
        localStorage.removeItem('client_id')
        localStorage.removeItem('id_token')
        localStorage.removeItem('last_updated')
      }

      // redirect
      if (options.localOnly !== true) {
        window.location = configuration.appBaseUrl.getValue() + '/next/AutoLogin.aspx'
      }

      // callback
      if (options.localOnly === true) {
        invokeCallback(callback)
      }
    })
  } else {
    // local storage
    localStorage.removeItem('active')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token_expires_in')
    localStorage.removeItem('access_token_expires_on')
    localStorage.removeItem('domain')
    localStorage.removeItem('client_id')
    localStorage.removeItem('id_token')
    localStorage.removeItem('last_updated')

    // redirect
    if (options.localOnly !== true) {
      window.location = configuration.appBaseUrl.getValue() + '/next/AutoLogin.aspx'
    }

    // callback
    if (options.localOnly === true) {
      invokeCallback(callback)
    }
  }
}

// service login request is a token refresh with an added service login parameter
function serviceLoginStart (serviceLoginUsername, callback) {
  // isAuthenticated
  if (!isAuthenticated()) {
    return logout({ localOnly: true }, callback)
  }

  // local storage
  const refreshToken = localStorage.getItem('refresh_token')

  loadRemoteConfigs(function () {
    const domain = localStorage.getItem('domain')
    const clientId = localStorage.getItem('client_id')

    // refresh
    refreshAuth0(domain, clientId, refreshToken, null, { serviceLoginUsername }, callback)
  })
}

function serviceLoginStop (callback) {
  // isAuthenticated
  if (!isAuthenticated()) {
    return logout({ localOnly: true }, callback)
  }

  // local storage
  const refreshToken = localStorage.getItem('refresh_token')

  loadRemoteConfigs(function () {
    const domain = localStorage.getItem('domain')
    const clientId = localStorage.getItem('client_id')

    // refresh
    refreshAuth0(domain, clientId, refreshToken, null, {}, callback)
  })
}

// init
if (isActive()) {
  localStorage.setItem('hasBeenActive', true)
}

// export
export default {
  initialized,
  init,
  isActive,
  hasBeenActive,
  refresh,
  logout,
  serviceLoginStart,
  serviceLoginStop
}
