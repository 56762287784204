import 'whatwg-fetch'
import Cookie from 'js-cookie'
import LocalStorage from './localStorage'
import resourceOwnerCodeFlow from './resourceOwnerCodeFlow'
import authorizationCodeFlow from './authorizationCodeFlow'
import global from './global'

function init () {
  const jwtCookieValues = []

  jwtCookieValues.push('2.3.1')
  jwtCookieValues.push('loaded')

  if (window) {
    jwtCookieValues.push('window')
  }

  if (document) {
    jwtCookieValues.push('document')
  }

  if (window && window.fetch) {
    jwtCookieValues.push('fetch')
  }

  if (LocalStorage.test()) {
    jwtCookieValues.push('localStorage')
  }

  if (LocalStorage.test_NS_ERROR_FILE_CORRUPTED()) {
    jwtCookieValues.push('localStorage_NS_ERROR_FILE_CORRUPTED')
  }

  if (LocalStorage.test_QuotaExceededError()) {
    jwtCookieValues.push('localStorage_QuotaExceededError')
  }

  if (resourceOwnerCodeFlow.initialized) {
    jwtCookieValues.push('resourceOwnerCodeFlowInitialized')

    if (resourceOwnerCodeFlow.hasBeenActive()) {
      jwtCookieValues.push('resourceOwnerCodeFlowHasBeenActive')
    }

    if (resourceOwnerCodeFlow.isActive()) {
      jwtCookieValues.push('resourceOwnerCodeFlowActive')
    }
  }

  if (authorizationCodeFlow.initialized) {
    jwtCookieValues.push('authorizationCodeFlowInitialized')

    if (authorizationCodeFlow.hasBeenActive()) {
      jwtCookieValues.push('authorizationCodeFlowHasBeenActive')
    }

    if (authorizationCodeFlow.isActive()) {
      jwtCookieValues.push('authorizationCodeFlowActive')
    }
  }

  if (global.initialized) {
    jwtCookieValues.push('global')
  }

  Cookie.set('jwt-status', jwtCookieValues.join('|'))
}

// init
init()

// init every 5s
setInterval(function () { init() }, 5000)

export default {
  init
}
