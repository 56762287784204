import configuration from './configuration'

function invokeCallback (callback, args) {
  if (callback) {
    args ? callback.apply(this, args) : callback()
  }
}

export default {
  log: function (message, callback) {
    try {
      if (message && message.message) {
        var ex = message
        var exUrl = ex.fileName != null ? ex.fileName : document.location

        // format output
        message = ex.message != null ? ex.name + ': ' + ex.message : ex
        message += ": at document path '" + exUrl + "'."
        if (ex.stack != null) message += '\n' + ex.stack
      }

      console.log(message)

      if (configuration.isEvestment.getValue()) {
        var url = configuration.appBaseUrl.getValue() + '/shared/Error/LogJavascriptError'
        var xhr = new XMLHttpRequest()
        xhr.onreadystatechange = function () {
          try {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              invokeCallback(callback)
            }
          } catch (e) {
            console.error(e)
            invokeCallback(callback, [e])
          }
        }
        xhr.open('POST', url, true)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify({ message: message }))
      }
    } catch (e) {
      console.error(e)
      invokeCallback(callback, [e])
    }
  }
}
